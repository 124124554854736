export const QUESTIONS = [
  {
    question: "На чем зарабатывает банк, если мне не нужно платить проценты? В чем подвох?",
    answer:
      "На самом деле никакого подвоха со стороны банка нет – схема максимально прозрачна. Вы покупаете товары у партнеров банка – магазины получают новых клиентов. Проценты вместо вас платит магазин, поэтому свою выгоду получает и банк. Клиент тоже остается в плюсе, поскольку не переплачивает за пользование рассрочкой и получает товар без наценки. ",
  },
  {
    question: "Процентная ставка по рассрочке",
    answer:
      "Поскольку мы предлагаем вам беспроцентную рассрочку, то и процентная ставка по ней составит 0%. Она будет действовать в течение всего льготного периода (36 месяцев). После его окончания ставка будет равна 0,0001%.",
  },
  {
    question: "Халва – кредитная или дебетовая карта?",
    answer:
      "Халва – универсальная карта, которую можно использовать одновременно как дебетовую и карту рассрочки. Сменить режим можно легко в приложении «Халва – Совкомбанк». ",
  },
  {
    question: "Какой у карты срок действия?",
    answer:
      "Халва будет действовать до последнего дня того месяца, который указан на лицевой стороне карты. Договор действителен в течение 10 лет, но сам пластик будет активен только 5 лет. Спустя это время мы бесплатно перевыпустим вам карту. Об истечении срока пластика и дате изготовления банк сообщит заранее. ",
  },
  {
    question: "Что будет, если просрочить ежемесячный платеж?",
    answer:
      "Если вы не внесли ежемесячный платеж вовремя, то начинает действовать «льготный период». Это значит, что в течение пяти дополнительных дней вы можете успеть погасить просрочку. <br/> <br/>   Если за это время клиент не вносит платеж, то на шестой день ему назначают неустойку. Она равна 20% годовым за один день просрочки. Штраф будут начислять ежемесячно после 15 числа, пока клиент не погасит долг.",
  },
  {
    question: "Какой минимальный и максимальный лимит рассрочки?",
    answer:
      "При получении карты рассрочки вам устанавливают минимальный лимит – 0,1 рубля. Максимальный лимит карты «Халва» составляет 500 000 рублей. Чтобы получить его, достаточно выполнять правила 5х10 и регулярно совершать покупки с помощью карты. Чем активнее вы будете пользоваться Халвой, тем выше вероятность того, что банк увеличит вам лимит.",
  },
  {
    question: "Как получить справки и выписки?",
    answer:
      "Получить справки и выписки по Халве можно несколькими способами: <ul><li>Посетить банк и получить необходимые данные в отделении;</li> <li>Запросить выписки через приложение в телефоне или личный кабинет <a rel='nofollow noopener'  target='_blank' href='https://halvacard.ru/lk'>на сайте «Халвы».</a></li></ul>",
  },
  {
    question: "Мои деньги на карте застрахованы?",
    answer:
      "Собственные средства клиента на карте застрахованы согласно <a rel='nofollow noopener'  target='_blank' href='https://www.consultant.ru/document/cons_doc_LAW_45769/'>ФЗ&nbsp;№177-ФЗ</a>.",
  },
  {
    question: "Есть ли платные услуги, которые подключены по умолчанию?",
    answer: "Нет, все услуги мы подключаем с согласия клиента. Они не являются обязательными.",
  },
  {
    question: "Влияет ли наличие карты на кредитную нагрузку?",
    answer:
      "Поскольку с помощью Халвы вы покупаете товары в рассрочку, наличие задолженности по карте влияет на кредитную нагрузку. Банк будет относиться к вам более лояльно, если вы будете активно пользоваться картой и своевременно вносить платежи.  ",
  },
  {
    question: "Буду ли я получать уведомления об операциях по карте? Сколько это стоит?",
    answer:
      "По всем операциям на вашей карте будут поступать уведомления. Для этого включите push-уведомления в приложении. <br/> <br/> Помимо этого, вы можете получать СМС-оповещения. Они будут бесплатными, если вы будете соблюдать два простых условия: заходить в приложение банка или личный кабинет на сайте Халвы не менее трех раз в месяц и не отключать push-уведомления в приложении. В ином случае СМС-оповещение станет платным и составит 99 рублей. <br/> <br/> Для клиентов банка старше 50 лет и тех, кто получил, но еще не воспользовался картой, СМС-уведомления бесплатные. ",
  },
  {
    question: "Насколько надежен банк, который выпускает карту? Где о нем можно узнать информацию?",
    answer:
      "За выпуск и обслуживание Халвы ответственен Совкомбанк. На странице банка вы можете найти <a rel='nofollow noopener' target='_blank' href='https://sovcombank.ru/about/info'>всю интересующую информацию</a> – лицензии, реквизиты, рейтинги финансовой организации. Там же вы можете прочитать об истории основания банка. <br/> <br/> Совкомбанк зарекомендовал себя как надежная организация и не раз становился лидером народного рейтинга. В 2018 году Халву признали <a rel='nofollow noopener'  target='_blank' href='https://www.banki.ru/bankofyear/2018/'>лучшей банковской картой года</a> , а в 2021 году банк <a rel='nofollow noopener' target='_blank' href='https://bankofyear.banki.ru/2021/'>возглавил «Народный рейтинг»*</a>. <br/> *по версии портала banki.ru",
  },
  {
    question: "Как получить ПИН-код карты?",
    answer:
      "Вы получите пластиковую карту в запечатанном конверте вместе с документами и инструкцией. Там же вы найдете ПИН-код от Халвы. При необходимости вы можете установить его самостоятельно. Для этого нужно открыть приложение или зайти в личный кабинет на сайте карты «Халва». ",
  },
  {
    question: "Как изменить ПИН-код?",
    answer:
      "Сменить ПИН-код можно через приложение. Сначала выберите карту и перейдите в раздел «Важно». Затем перейдите в «Действия» и нажмите «Сменить ПИН-код». <br/> <br/> Также вы можете сменить пароль, позвонив по телефону бесплатной и круглосуточной горячей линии банка: 8-800-100-00-06.",
  },
  {
    question: "Забыл ПИН-код Халвы – что делать?",
    answer:
      "Если вы забыли ПИН-код от карты, то его можно легко поменять в приложении «Халва – Совкомбанк». Для этого выберите нужную карту, затем перейдите в раздел «Важно», «Действия» и «Сменить ПИН-код». Введите новый пароль и подтвердите его. <br/> <br/> Вы также можете сменить его, если позвоните по телефону бесплатной и круглосуточной горячей линии банка: 8-800-100-00-06. <br/> <br/> Также вы можете обратиться в отделение банка. Не забудьте взять с собой паспорт.",
  },
  {
    question: "Где я могу найти реквизиты моей Халвы и других карт?",
    answer:
      "Реквизиты карты можно найти в приложении банка. Достаточно зайти в раздел «Важно» и выбрать «Реквизиты карты». Также информацию можно найти в личном кабинете на сайте Халвы, выбрав раздел «Важно» и «О карте».",
  },
]
export const QUESTIONS_NEW = [
  {
    question: "На чем зарабатывает банк, если мне не нужно платить проценты? В чем подвох?",
    answer:
      "На самом деле никакого подвоха со стороны банка нет – схема максимально прозрачна. Вы покупаете товары у партнеров банка – магазины получают новых клиентов. Проценты вместо вас платит магазин, поэтому свою выгоду получает и банк. Клиент тоже остается в плюсе, поскольку не переплачивает за пользование рассрочкой и получает товар без наценки. ",
  },
  {
    question: "Процентная ставка по рассрочке",
    answer:
      "Поскольку мы предлагаем вам беспроцентную рассрочку, то и процентная ставка по ней составит 0%. Она будет действовать в течение всего льготного периода (36 месяцев). После его окончания ставка будет равна 0,0001%.",
  },
  {
    question: "Халва – кредитная или дебетовая карта?",
    answer:
      "Халва – универсальная карта, которую можно использовать одновременно как дебетовую и карту рассрочки. Сменить режим можно легко в приложении «Халва – Совкомбанк». ",
  },
  {
    question: "Какой у карты срок действия?",
    answer:
      "Халва будет действовать до последнего дня того месяца, который указан на лицевой стороне карты. Договор действителен в течение 10 лет, но сам пластик будет активен только 5 лет. Спустя это время мы бесплатно перевыпустим вам карту. Об истечении срока пластика и дате изготовления банк сообщит заранее. ",
  },
  {
    question: "Что будет, если просрочить ежемесячный платеж?",
    answer:
      "Если вы не внесли ежемесячный платеж вовремя, то начинает действовать «льготный период». Это значит, что в течение пяти дополнительных дней вы можете успеть погасить просрочку. <br/> <br/>   Если за это время клиент не вносит платеж, то на шестой день ему назначают неустойку. Она равна 20% годовым за один день просрочки. Штраф будут начислять ежемесячно после 15 числа, пока клиент не погасит долг.",
  },
  {
    question: "Какой минимальный и максимальный лимит рассрочки?",
    answer:
      "При получении карты рассрочки вам устанавливают минимальный лимит – 0,1 рубля. Максимальный лимит карты «Халва» составляет 500 000 рублей. Чтобы получить его, достаточно выполнять правила 5х10 и регулярно совершать покупки с помощью карты. Чем активнее вы будете пользоваться Халвой, тем выше вероятность того, что банк увеличит вам лимит.",
  },
  {
    question: "Как получить справки и выписки?",
    answer:
      "Получить справки и выписки по Халве можно несколькими способами: <ul><li>Посетить банк и получить необходимые данные в отделении;</li> <li>Запросить выписки через приложение в телефоне или личный кабинет <a rel='nofollow noopener'  target='_blank' href='https://halvacard.ru/lk'>на сайте «Халвы».</a></li></ul>",
  },
  {
    question: "Мои деньги на карте застрахованы?",
    answer:
      "Собственные средства клиента на карте застрахованы согласно <a rel='nofollow noopener'  target='_blank' href='https://www.consultant.ru/document/cons_doc_LAW_45769/'>ФЗ&nbsp;№177-ФЗ</a>.",
  },
  {
    question: "Есть ли платные услуги, которые подключены по умолчанию?",
    answer: "Нет, все услуги мы подключаем с согласия клиента. Они не являются обязательными.",
  },
  {
    question: "Влияет ли наличие карты на кредитную нагрузку?",
    answer:
      "Поскольку с помощью Халвы вы покупаете товары в рассрочку, наличие задолженности по карте влияет на кредитную нагрузку. Банк будет относиться к вам более лояльно, если вы будете активно пользоваться картой и своевременно вносить платежи.  ",
  },
  {
    question: "Буду ли я получать уведомления об операциях по карте? Сколько это стоит?",
    answer:
      "По всем операциям на вашей карте будут поступать уведомления. Для этого включите push-уведомления в приложении. <br/> <br/> Помимо этого, вы можете получать СМС-оповещения. Они будут бесплатными, если вы будете соблюдать два простых условия: заходить в приложение банка или личный кабинет на сайте Халвы не менее трех раз в месяц и не отключать push-уведомления в приложении. В ином случае СМС-оповещение станет платным и составит 99 рублей. <br/> <br/> Для клиентов банка старше 50 лет и тех, кто получил, но еще не воспользовался картой, СМС-уведомления бесплатные. ",
  },
  {
    question: "Насколько надежен банк, который выпускает карту? Где о нем можно узнать информацию?",
    answer:
      "За выпуск и обслуживание Халвы ответственен Совкомбанк. На странице банка вы можете найти <a rel='nofollow noopener' target='_blank' href='https://sovcombank.ru/about/info'>всю интересующую информацию</a> – лицензии, реквизиты, рейтинги финансовой организации. Там же вы можете прочитать об истории основания банка. <br/> <br/> Совкомбанк зарекомендовал себя как надежная организация и не раз становился лидером народного рейтинга. В 2018 году Халву признали лучшей банковской картой года, а в 2022 году <a rel='nofollow noopener' target='_blank' href='https://sovcombank.ru/articles/novosti-kompanii/sovkombank-priznan-pobeditelem-narodnogo-reitinga-banki-ru-po-itogam-2022-goda'>банк возглавил «Народный рейтинг»</a> *в четвертый раз подряд. <br/> *по версии портала <a rel='nofollow noopener' target='_blank' href='https://www.banki.ru/'>banki.ru </a>",
  },
  {
    question: "Как получить ПИН-код карты?",
    answer:
      "Вы получите пластиковую карту в запечатанном конверте вместе с документами и инструкцией. Там же вы найдете ПИН-код от Халвы. При необходимости вы можете установить его самостоятельно. Для этого нужно открыть приложение или зайти в личный кабинет на сайте карты «Халва». ",
  },
  {
    question: "Как изменить ПИН-код?",
    answer:
      "Сменить ПИН-код можно через приложение. Сначала выберите карту и перейдите в раздел «Важно». Затем перейдите в «Действия» и нажмите «Сменить ПИН-код». <br/> <br/> Также вы можете сменить пароль, позвонив по телефону бесплатной и круглосуточной горячей линии банка: 8-800-100-00-06.",
  },
  {
    question: "Забыл ПИН-код Халвы – что делать?",
    answer:
      "Если вы забыли ПИН-код от карты, то его можно легко поменять в приложении «Халва – Совкомбанк». Для этого выберите нужную карту, затем перейдите в раздел «Важно», «Действия» и «Сменить ПИН-код». Введите новый пароль и подтвердите его. <br/> <br/> Вы также можете сменить его, если позвоните по телефону бесплатной и круглосуточной горячей линии банка: 8-800-100-00-06. <br/> <br/> Также вы можете обратиться в отделение банка. Не забудьте взять с собой паспорт.",
  },
  {
    question: "Где я могу найти реквизиты моей Халвы и других карт?",
    answer:
      "Реквизиты карты можно найти в приложении банка. Достаточно зайти в раздел «Важно» и выбрать «Реквизиты карты». Также информацию можно найти в личном кабинете на сайте Халвы, выбрав раздел «Важно» и «О карте».",
  },
]
export const QUESTIONS_FOR_PKW = [
  {
    question: "На чем зарабатывает банк, если мне не нужно платить проценты? В чем подвох?",
    answer:
      "На самом деле никакого подвоха со стороны банка нет – схема максимально прозрачна. Вы покупаете товары у партнеров банка – магазины получают новых клиентов. Проценты вместо вас платит магазин, поэтому свою выгоду получает и банк. Клиент тоже остается в плюсе, поскольку не переплачивает за пользование рассрочкой и получает товар без наценки. ",
  },
  {
    question: "Процентная ставка по рассрочке",
    answer:
      "Поскольку мы предлагаем вам беспроцентную рассрочку, то и процентная ставка по ней составит 0%. Она будет действовать в течение всего льготного периода (36 месяцев). После его окончания ставка будет равна 0,0001%.",
  },
  {
    question: "Халва – кредитная или дебетовая карта?",
    answer:
      "Халва – универсальная карта, которую можно использовать одновременно как дебетовую и карту рассрочки. Сменить режим можно легко в приложении «Халва – Совкомбанк». ",
  },
  {
    question: "Какой у карты срок действия?",
    answer:
      "Срок действия карты Рассрочки  Халва  7 лет. В конце срока предусмотрен автоматический перевыпуск.  Достаточно будет прийти за новой картой с паспортом в любое отделение банка.  Перевыпуск и обслуживание карты Совкомбанка полностью бесплатные.",
  },
  {
    question: "Что будет, если просрочить ежемесячный платеж?",
    answer:
      "Если вы не внесли ежемесячный платеж вовремя, то начинает действовать «льготный период». Это значит, что в течение пяти дополнительных дней вы можете успеть погасить просрочку. <br/> <br/>   Если за это время клиент не вносит платеж, то на шестой день ему назначают неустойку. Она равна 20% годовым за один день просрочки. Штраф будут начислять ежемесячно после 15 числа, пока клиент не погасит долг.",
  },
  {
    question: "Какой минимальный и максимальный лимит рассрочки?",
    answer:
      "При получении карты рассрочки вам устанавливают минимальный лимит – 0,1 рубля. Максимальный лимит карты «Халва» составляет 500 000 рублей. Чтобы получить его, достаточно выполнять правила 5х10 и регулярно совершать покупки с помощью карты. Чем активнее вы будете пользоваться Халвой, тем выше вероятность того, что банк увеличит вам лимит.",
  },
  {
    question: "Как получить справки и выписки?",
    answer:
      "Получить справки и выписки по Халве можно несколькими способами: <ul><li>Посетить банк и получить необходимые данные в отделении;</li> <li>Запросить выписки через приложение в телефоне или личный кабинет <a rel='nofollow noopener'  target='_blank' href='https://halvacard.ru/lk'>на сайте «Халвы».</a></li></ul>",
  },
  {
    question: "Мои деньги на карте застрахованы?",
    answer:
      "Собственные средства клиента на карте застрахованы согласно <a rel='nofollow noopener'  target='_blank' href='https://www.consultant.ru/document/cons_doc_LAW_45769/'>ФЗ&nbsp;№177-ФЗ</a>.",
  },
  {
    question: "Есть ли платные услуги, которые подключены по умолчанию?",
    answer: "Нет, все услуги мы подключаем с согласия клиента. Они не являются обязательными.",
  },
  {
    question: "Влияет ли наличие карты на кредитную нагрузку?",
    answer:
      "Поскольку с помощью Халвы вы покупаете товары в рассрочку, наличие задолженности по карте влияет на кредитную нагрузку. Банк будет относиться к вам более лояльно, если вы будете активно пользоваться картой и своевременно вносить платежи.  ",
  },
  {
    question: "Буду ли я получать уведомления об операциях по карте? Сколько это стоит?",
    answer:
      "По всем операциям на вашей карте будут поступать уведомления. Для этого включите push-уведомления в приложении. <br/> <br/> Помимо этого, вы можете получать СМС-оповещения. Они будут бесплатными, если вы будете соблюдать два простых условия: заходить в приложение банка или личный кабинет на сайте Халвы не менее трех раз в месяц и не отключать push-уведомления в приложении. В ином случае СМС-оповещение станет платным и составит 99 рублей. <br/> <br/> Для клиентов банка старше 50 лет и тех, кто получил, но еще не воспользовался картой, СМС-уведомления бесплатные. ",
  },
  {
    question: "Насколько надежен банк, который выпускает карту? Где о нем можно узнать информацию?",
    answer:
      "За выпуск и обслуживание Халвы ответственен Совкомбанк. На странице банка вы можете найти <a rel='nofollow noopener' target='_blank' href='https://sovcombank.ru/about/info'>всю интересующую информацию</a> – лицензии, реквизиты, рейтинги финансовой организации. Там же вы можете прочитать об истории основания банка. <br/> <br/> Совкомбанк зарекомендовал себя как надежная организация и не раз становился лидером народного рейтинга. В 2018 году Халву признали лучшей банковской картой года, а в 2022 году <a rel='nofollow noopener' target='_blank' href='https://sovcombank.ru/articles/novosti-kompanii/sovkombank-priznan-pobeditelem-narodnogo-reitinga-banki-ru-po-itogam-2022-goda'>банк возглавил «Народный рейтинг»</a> *в четвертый раз подряд. <br/> *по версии портала <a rel='nofollow noopener' target='_blank' href='https://www.banki.ru/'>banki.ru </a>",
  },
  {
    question: "Как получить ПИН-код карты?",
    answer:
      "Вы получите пластиковую карту в запечатанном конверте вместе с документами и инструкцией. Там же вы найдете ПИН-код от Халвы. При необходимости вы можете установить его самостоятельно. Для этого нужно открыть приложение или зайти в личный кабинет на сайте карты «Халва». ",
  },
  {
    question: "Как изменить ПИН-код?",
    answer:
      "Cменить пин-код самостоятельно в Мобильном приложении.<br/> <br/> Cменить пин-код самостоятельно через Чат-Банк. <br/> <br/>Клиенту необходимо обратиться в офис ПАО Совкомбанк лично с паспортом и оставить письменное заявление на смену PIN-кода. Далее Вам будет предложено перезвонить с номера, указанного в заявлении, на номер 8-800-100-0006 и нажать цифру 4.",
  },
  {
    question: "Забыл ПИН-код Халвы – что делать?",
    answer:
      "Если вы забыли ПИН-код от карты, то его можно легко поменять в приложении «Халва – Совкомбанк». Для этого выберите нужную карту, затем перейдите в раздел «Важно», «Действия» и «Сменить ПИН-код». Введите новый пароль и подтвердите его. <br/> <br/> Вы также можете сменить его, если позвоните по телефону бесплатной и круглосуточной горячей линии банка: 8-800-100-00-06. <br/> <br/> Также вы можете обратиться в отделение банка. Не забудьте взять с собой паспорт.",
  },
  {
    question: "Где я могу найти реквизиты моей Халвы и других карт?",
    answer:
      "Реквизиты карты можно найти в приложении банка. Достаточно зайти в раздел «Важно» и выбрать «Реквизиты карты». Также информацию можно найти в личном кабинете на сайте Халвы, выбрав раздел «Важно» и «О карте».",
  },
]
export const QUESTIONS_FOR_PKW_MEDIA = [
  {
    question: "На чем зарабатывает банк, если мне не нужно платить проценты? В чем подвох?",
    answer:
      "На самом деле никакого подвоха со стороны банка нет – схема максимально прозрачна. Вы покупаете товары у партнеров банка – магазины получают новых клиентов. Проценты вместо вас платит магазин, поэтому свою выгоду получает и банк. Клиент тоже остается в плюсе, поскольку не переплачивает за пользование рассрочкой и получает товар без наценки. ",
  },
  {
    question: "Процентная ставка по рассрочке",
    answer:
      "Поскольку мы предлагаем вам беспроцентную рассрочку, то и процентная ставка по ней составит 0%. Она будет действовать в течение всего льготного периода (36 месяцев). После его окончания ставка будет равна 0,0001%.",
  },
  {
    question: "Халва – кредитная или дебетовая карта?",
    answer:
      "Халва – универсальная карта, которую можно использовать одновременно как дебетовую и карту рассрочки. Сменить режим можно легко в приложении «Халва – Совкомбанк». ",
  },
  {
    question: "Какой у карты срок действия?",
    answer:
      "Срок действия карты Рассрочки  Халва  7 лет. В конце срока предусмотрен автоматический перевыпуск.  Достаточно будет прийти за новой картой с паспортом в любое отделение банка.  Перевыпуск и обслуживание карты Совкомбанка полностью бесплатные.",
  },
  {
    question: "Что будет, если просрочить ежемесячный платеж?",
    answer:
      "Если вы не внесли ежемесячный платеж вовремя, то начинает действовать «льготный период». Это значит, что в течение пяти дополнительных дней вы можете успеть погасить просрочку. <br/> <br/>   Если за это время клиент не вносит платеж, то на шестой день ему назначают неустойку. Она равна 20% годовым за один день просрочки. Штраф будут начислять ежемесячно после 15 числа, пока клиент не погасит долг.",
  },
  {
    question: "Какой минимальный и максимальный лимит рассрочки?",
    answer:
      "При получении карты рассрочки вам устанавливают минимальный лимит – 0,1 рубля. Максимальный лимит карты «Халва» составляет 500 000 рублей. Чтобы получить его, достаточно выполнять правила 5х10 и регулярно совершать покупки с помощью карты. Чем активнее вы будете пользоваться Халвой, тем выше вероятность того, что банк увеличит вам лимит.",
  },
  {
    question: "Как получить справки и выписки?",
    answer:
      "Получить справки и выписки по Халве можно несколькими способами: <ul><li>Посетить банк и получить необходимые данные в отделении;</li> <li>Запросить выписки через приложение в телефоне или личный кабинет <a rel='nofollow noopener'  target='_blank' href='https://halvacard.ru/lk'>на сайте «Халвы».</a></li></ul>",
  },
  {
    question: "Мои деньги на карте застрахованы?",
    answer:
      "Собственные средства клиента на карте застрахованы согласно <a rel='nofollow noopener'  target='_blank' href='https://www.consultant.ru/document/cons_doc_LAW_45769/'>ФЗ&nbsp;№177-ФЗ</a>.",
  },
  {
    question: "Есть ли платные услуги, которые подключены по умолчанию?",
    answer: "Нет, все услуги мы подключаем с согласия клиента. Они не являются обязательными.",
  },
  {
    question: "Влияет ли наличие карты на кредитную нагрузку?",
    answer:
      "Поскольку с помощью Халвы вы покупаете товары в рассрочку, наличие задолженности по карте влияет на кредитную нагрузку. Банк будет относиться к вам более лояльно, если вы будете активно пользоваться картой и своевременно вносить платежи.  ",
  },
  {
    question: "Насколько надежен банк, который выпускает карту? Где о нем можно узнать информацию?",
    answer:
      "За выпуск и обслуживание Халвы ответственен Совкомбанк. На странице банка вы можете найти <a rel='nofollow noopener' target='_blank' href='https://sovcombank.ru/about/info'>всю интересующую информацию</a> – лицензии, реквизиты, рейтинги финансовой организации. Там же вы можете прочитать об истории основания банка. <br/> <br/> Совкомбанк зарекомендовал себя как надежная организация и не раз становился лидером народного рейтинга. В 2018 году Халву признали лучшей банковской картой года, а в 2022 году <a rel='nofollow noopener' target='_blank' href='https://sovcombank.ru/articles/novosti-kompanii/sovkombank-priznan-pobeditelem-narodnogo-reitinga-banki-ru-po-itogam-2022-goda'>банк возглавил «Народный рейтинг»</a> *в четвертый раз подряд. <br/> *по версии портала <a rel='nofollow noopener' target='_blank' href='https://www.banki.ru/'>banki.ru </a>",
  },
  {
    question: "Как получить ПИН-код карты?",
    answer:
      "Вы получите пластиковую карту в запечатанном конверте вместе с документами и инструкцией. Там же вы найдете ПИН-код от Халвы. При необходимости вы можете установить его самостоятельно. Для этого нужно открыть приложение или зайти в личный кабинет на сайте карты «Халва». ",
  },
  {
    question: "Как изменить ПИН-код?",
    answer:
      "Cменить пин-код самостоятельно в Мобильном приложении.<br/> <br/> Cменить пин-код самостоятельно через Чат-Банк. <br/> <br/>Клиенту необходимо обратиться в офис ПАО Совкомбанк лично с паспортом и оставить письменное заявление на смену PIN-кода. Далее Вам будет предложено перезвонить с номера, указанного в заявлении, на номер 8-800-100-0006 и нажать цифру 4.",
  },
  {
    question: "Забыл ПИН-код Халвы – что делать?",
    answer:
      "Если вы забыли ПИН-код от карты, то его можно легко поменять в приложении «Халва – Совкомбанк». Для этого выберите нужную карту, затем перейдите в раздел «Важно», «Действия» и «Сменить ПИН-код». Введите новый пароль и подтвердите его. <br/> <br/> Вы также можете сменить его, если позвоните по телефону бесплатной и круглосуточной горячей линии банка: 8-800-100-00-06. <br/> <br/> Также вы можете обратиться в отделение банка. Не забудьте взять с собой паспорт.",
  },
  {
    question: "Где я могу найти реквизиты моей Халвы и других карт?",
    answer:
      "Реквизиты карты можно найти в приложении банка. Достаточно зайти в раздел «Важно» и выбрать «Реквизиты карты». Также информацию можно найти в личном кабинете на сайте Халвы, выбрав раздел «Важно» и «О карте».",
  },
]
export const QUESTIONS_FOR_INDEX_PKW = [
  {
    question: "Халва – кредитная или дебетовая карта?",
    answer:
      "Халва – универсальная карта, которую можно использовать одновременно как дебетовую и карту рассрочки. Сменить режим можно легко в приложении «Халва – Совкомбанк». ",
  },
  {
    question: "Какой минимальный и максимальный лимит рассрочки?",
    answer:
      "Минимальный лимит по карте «Халва» – 0,1 рубля. Максимальный лимит карты «Халва» составляет 500 000 рублей. Чтобы увеличить лимит выполняйте правила 5х10 и регулярно совершайте покупки с помощью карты. Чем активнее вы будете пользоваться Халвой, тем выше вероятность того, что банк увеличит вам лимит.",
  },
  {
    question: "Влияет ли наличие карты на кредитную нагрузку?",
    answer:
      "Поскольку с помощью Халвы вы покупаете товары в рассрочку, наличие задолженности по карте влияет на кредитную нагрузку. Банк будет относиться к вам более лояльно, если вы будете активно пользоваться картой и своевременно вносить платежи.  ",
  },
  {
    question: "Насколько надежен банк, который выпускает карту? Где о нем можно узнать информацию?",
    answer:
      "За выпуск и обслуживание Халвы ответственен Совкомбанк. На странице банка вы можете найти <a rel='nofollow noopener' target='_blank' href='https://sovcombank.ru/about/info'>всю интересующую информацию</a> – лицензии, реквизиты, рейтинги финансовой организации. Там же вы можете прочитать об истории основания банка. <br/> <br/> Совкомбанк зарекомендовал себя как надежная организация и не раз становился лидером народного рейтинга. В 2018 году Халву признали лучшей банковской картой года, а в 2022 году <a rel='nofollow noopener' target='_blank' href='https://sovcombank.ru/articles/novosti-kompanii/sovkombank-priznan-pobeditelem-narodnogo-reitinga-banki-ru-po-itogam-2022-goda'>банк возглавил «Народный рейтинг»</a> *в четвертый раз подряд. <br/> *по версии портала <a rel='nofollow noopener' target='_blank' href='https://www.banki.ru/'>banki.ru </a>",
  },
  {
    question: "Что такое подписка «Халва.Десятка»?",
    answer:
      "Это платная услуга, с которой вы получаете дополнительные преимущества:<ul><li>увеличенный срок рассрочки у всех партнеров — от 10 месяцев;</li><li>до 17% годовых на остаток своих средств;</li><li>кэшбэк до 10%;</li><li>+1% к ставке вклада при ежемесячном выполнении ПРАВИЛА 5х10;</li><li>услуги личного консультанта по покупкам;</li><li>страхование жизни и здоровья и многое другое;</li><li>+1 месяц рассрочки на снятия, переводы и покупки не у партнёров заемными средствами.</li></ul><br/>Подробнее о Подписке и ее&nbsp;<a rel='nofollow noopener' target='_blank' href='https://halvacard.ru/halvadesyatka/'>преимуществах</a>",
  },
  {
    question: "Сколько времени рассматривают заявку на Халву?",
    answer:
      "Вы получите решение через несколько минут после того, как отправите заявку. В редких случаях на это может потребоваться 1 рабочий день.",
  },
]

export const QUESTIONS_FOR_ROSTICS = [
  {
    question: "Халва – кредитная или дебетовая карта?",
    answer:
      "Халва – универсальная карта, которую можно использовать одновременно как дебетовую и карту рассрочки. Сменить режим можно легко в приложении «Халва – Совкомбанк». ",
  },
  {
    question: "Какой минимальный и максимальный лимит рассрочки?",
    answer:
      "Минимальный лимит по карте «Халва» – 0,1 рубля. Максимальный лимит карты «Халва» составляет 500 000 рублей. Чтобы увеличить лимит выполняйте правила 5х10 и регулярно совершайте покупки с помощью карты. Чем активнее вы будете пользоваться Халвой, тем выше вероятность того, что банк увеличит вам лимит.",
  },
  {
    question: "Как получить промокод на кофе за 1 р.?",
    answer:
      "После оформления карты в мобильном приложении Халвы, в разделе «Витрина подарков» вам будут доступны 7 промокодов на кофе за 1 ₽ от ROSTIC’S. После активации акция отобразится в разделе «Активированные», и ваш код станет доступен для применения.",
  },
  {
    question: "Как применить промокод ?",
    answer:
      "Применить промокод можно в мобильном приложении ROSTIC’S. Промокоды действительны при заказе от 99 ₽ до 01.04.2025. Подробнее на сайте: <a rel='nofollow noreferrer noopener' target='_blank' href='https://rostics.ru/promo/halva'>https://rostics.ru/promo/halva</a>.",
  },
  {
    question: "Где ознакомиться с полными Правилами Акции?",
    answer:
      "Полные Правила Акции можно прочитать по ссылке <a rel='nofollow noopener'  target='_blank' href='https://sovcombank.ru/document/14498'>https://sovcombank.ru/document/14498</a>.",
  },
  {
    question: "Сколько времени рассматривают заявку на Халву?",
    answer:
      "Вы получите решение через несколько минут после того, как отправите заявку. В редких случаях на это может потребоваться 1 рабочий день.",
  },
  {
    question: "Какие рестораны участвуют в акции?",
    answer:
      "С полным списком ресторанов ROSTIC’S, участвующих в акции, можно ознакомиться в Паспорте Акции <a rel='nofollow noopener'  target='_blank' href='https://sovcombank.ru/document/14498'>https://sovcombank.ru/document/14498</a>.",
  },
]

export const QUESTIONS_FOR_INDEX_ZOON = [
  {
    question: "Халва – кредитная или дебетовая карта?",
    answer:
      "Халва – универсальная карта, которую можно использовать одновременно как дебетовую и карту рассрочки. Сменить режим можно легко в приложении «Халва – Совкомбанк». ",
  },
  {
    question: "Какой минимальный и максимальный лимит рассрочки?",
    answer:
      "Минимальный лимит по карте «Халва» – 0,1 рубля. Максимальный лимит карты «Халва» составляет 500 000 рублей. Чтобы увеличить лимит выполняйте правила 5х10 и регулярно совершайте покупки с помощью карты. Чем активнее вы будете пользоваться Халвой, тем выше вероятность того, что банк увеличит вам лимит.",
  },
  {
    question: "Влияет ли наличие карты на кредитную нагрузку?",
    answer:
      "Поскольку с помощью Халвы вы покупаете товары в рассрочку, наличие задолженности по карте влияет на кредитную нагрузку. Банк будет относиться к вам более лояльно, если вы будете активно пользоваться картой и своевременно вносить платежи.  ",
  },
  {
    question: "Насколько надежен банк, который выпускает карту? Где о нем можно узнать информацию?",
    answer:
      "За выпуск и обслуживание Халвы ответственен Совкомбанк. На странице банка вы можете найти <a rel='nofollow noopener' target='_blank' href='https://sovcombank.ru/about/info'>всю интересующую информацию</a> – лицензии, реквизиты, рейтинги финансовой организации. Там же вы можете прочитать об истории основания банка. <br/> <br/> Совкомбанк зарекомендовал себя как надежная организация и не раз становился лидером народного рейтинга. В 2018 году Халву признали лучшей банковской картой года, а в 2022 году <a rel='nofollow noopener' target='_blank' href='https://sovcombank.ru/articles/novosti-kompanii/sovkombank-priznan-pobeditelem-narodnogo-reitinga-banki-ru-po-itogam-2022-goda'>банк возглавил «Народный рейтинг»</a> *в четвертый раз подряд. <br/> *по версии портала <a rel='nofollow noopener' target='_blank' href='https://www.banki.ru/'>banki.ru </a>",
  },
  {
    question: "Что такое подписка «Халва.Десятка»?",
    answer:
      "Это платная услуга, с которой вы получаете дополнительные преимущества:<ul><li>увеличенный срок рассрочки у всех партнеров — от 10 месяцев;</li><li>до 17% годовых на остаток своих средств;</li><li>кэшбэк до 10%;</li><li>+1% к ставке вклада при ежемесячном выполнении ПРАВИЛА 5х10;</li><li>услуги личного консультанта по покупкам;</li><li>страхование жизни и здоровья и многое другое;</li><li>+1 месяц рассрочки на снятия, переводы и покупки не у партнёров заемными средствами.</li></ul><br/>Подробнее о Подписке и ее&nbsp;<a rel='nofollow noopener' target='_blank' href='https://halvacard.ru/halvadesyatka/'>преимуществах</a>",
  },
  {
    question: "Сколько времени рассматривают заявку на Халву?",
    answer:
      "Вы получите решение через несколько минут после того, как отправите заявку. В редких случаях на это может потребоваться 1 рабочий день.",
  },
  {
    question: "Когда я получу кэшбэк?",
    answer:
      "Баллы Кэшбэка  начисляются не позднее следующего дня за днем расчета МОП (Минимальный Обязательный Платеж) за следующий отчётный период, и могут быть переведены в рубли согласно положениям указанной программы: 1 балл = 1 рубль.",
  },
]

export const QUESTIONS_FOR_LENTA = [
  {
    question: "Халва – кредитная или дебетовая карта?",
    answer:
      "Халва – универсальная карта, которую можно использовать одновременно как дебетовую и карту рассрочки. Сменить режим можно легко в приложении «Халва – Совкомбанк». ",
  },
  {
    question: "Какой у карты срок действия?",
    answer:
      "Срок действия карты Рассрочки  Халва  7 лет. В конце срока предусмотрен автоматический перевыпуск.  Достаточно будет прийти за новой картой с паспортом в любое отделение банка.  Перевыпуск и обслуживание карты Совкомбанка полностью бесплатные.",
  },
  {
    question: "Что будет, если просрочить ежемесячный платеж?",
    answer:
      "Если вы не внесли ежемесячный платеж вовремя, то начинает действовать «льготный период». Это значит, что в течение пяти дополнительных дней вы можете успеть погасить просрочку. <br/> <br/>   Если за это время клиент не вносит платеж, то на шестой день ему назначают неустойку. Она равна 20% годовым за один день просрочки. Штраф будут начислять ежемесячно после 15 числа, пока клиент не погасит долг.",
  },
  {
    question: "Какой минимальный и максимальный лимит рассрочки?",
    answer:
      "При получении карты рассрочки вам устанавливают минимальный лимит – 0,1 рубля. Максимальный лимит карты «Халва» составляет 500 000 рублей. Чтобы получить его, достаточно выполнять правила 5х10 и регулярно совершать покупки с помощью карты. Чем активнее вы будете пользоваться Халвой, тем выше вероятность того, что банк увеличит вам лимит.",
  },
  {
    question: "Есть ли платные услуги, которые подключены по умолчанию?",
    answer: "Нет, все услуги мы подключаем с согласия клиента. Они не являются обязательными.",
  },
  {
    question: "Влияет ли наличие карты на кредитную нагрузку?",
    answer:
      "Поскольку с помощью Халвы вы покупаете товары в рассрочку, наличие задолженности по карте влияет на кредитную нагрузку. Банк будет относиться к вам более лояльно, если вы будете активно пользоваться картой и своевременно вносить платежи.  ",
  },
  {
    question: "Насколько надежен банк, который выпускает карту? Где о нем можно узнать информацию?",
    answer:
      "За выпуск и обслуживание Халвы ответственен Совкомбанк. На странице банка вы можете найти <a rel='nofollow noopener' target='_blank' href='https://sovcombank.ru/about/info'>всю интересующую информацию</a> – лицензии, реквизиты, рейтинги финансовой организации. Там же вы можете прочитать об истории основания банка. <br/> <br/> Совкомбанк зарекомендовал себя как надежная организация и не раз становился лидером народного рейтинга. В 2018 году Халву признали лучшей банковской картой года, а в 2022 году <a rel='nofollow noopener' target='_blank' href='https://sovcombank.ru/articles/novosti-kompanii/sovkombank-priznan-pobeditelem-narodnogo-reitinga-banki-ru-po-itogam-2022-goda'>банк возглавил «Народный рейтинг»</a> *в четвертый раз подряд. <br/> *по версии портала <a rel='nofollow noopener' target='_blank' href='https://www.banki.ru/'>banki.ru </a>",
  },
  {
    question: "Что такое подписка «Халва.Десятка»?",
    answer:
      "Это платная услуга, с которой вы получаете дополнительные преимущества:<ul><li>увеличенный срок рассрочки у всех партнеров — от 10 месяцев;</li><li>до 17% годовых на остаток своих средств;</li><li>кэшбэк до 10%;</li><li>+1% к ставке вклада при ежемесячном выполнении ПРАВИЛА 5х10;</li><li>услуги личного консультанта по покупкам;</li><li>страхование жизни и здоровья и многое другое;</li><li>+1 месяц рассрочки на снятия, переводы и покупки не у партнёров заемными средствами.</li></ul><br/>Подробнее о Подписке и ее&nbsp;<a rel='nofollow noopener' target='_blank' href='https://halvacard.ru/halvadesyatka/'>преимуществах</a>",
  },
  {
    question: "Сколько времени рассматривают заявку на Халву?",
    answer:
      "Вы получите решение через несколько минут после того, как отправите заявку. В редких случаях на это может потребоваться 1 рабочий день.",
  },
  {
    question: "Когда я получу кэшбэк?",
    answer: "Кэшбэк будет начислен на Карту №1 от «Ленты» до 20 июня 2024 г. 1 балл = 1 рублю.",
  },
  {
    question: "Какой срок действия баллов?",
    answer: "Срок действия баллов составляет 6 (шесть) месяцев с даты начисления на Карту №1.",
  },
  {
    question: "Как оплатить покупку баллами?",
    answer:
      "В магазинах «Лента» предъявите свою «Карту №1*» или карту «ЛЕНТА-Райффайзенбанк» на кассе и сообщите кассиру, что хотите оплатить покупку баллами. При оформлении заказа в доставке «Лента Онлайн» нажмите «Применить баллы», предварительно привязав Карту №1. Полные правила начисления и списания баллов указаны в Правилах программы лояльности «Все включено!» <a rel='nofollow noopener' target='_blank' href='https://lenta.com/info/loyalty-program-rules/'>на сайте</a>.",
  },
  {
    question: "Сколько баллов я могу списать при оплате покупки?",
    answer:
      "В магазинах «Лента» баллы применяются как скидка на товар до 100% от его стоимости по курсу 1 балл = 1 рубль. В доставке «Лента Онлайн» баллы применяются как скидка на товар до 5% от его стоимости по курсу 1 балл = 1 рубль. В случае, если сумма заказа в доставке уменьшилась после сборки заказа, сумма списания баллов будет пересчитана в соответствии с Правилами. Если сумма заказа увеличилась, сумма баллов к списанию не изменится.",
  },
]

export const QUESTIONS_FOR_GETHALVA = [
  {
    question: "Халва – кредитная или дебетовая карта?",
    answer:
      "Халва – универсальная карта, которую можно использовать одновременно как дебетовую и карту рассрочки. Сменить режим можно легко в приложении «Халва – Совкомбанк». ",
  },
  {
    question: "Какой у карты срок действия?",
    answer:
      "Срок действия карты Рассрочки  Халва  7 лет. В конце срока предусмотрен автоматический перевыпуск.  Достаточно будет прийти за новой картой с паспортом в любое отделение банка.  Перевыпуск и обслуживание карты Совкомбанка полностью бесплатные.",
  },
  {
    question: "Что будет, если просрочить ежемесячный платеж?",
    answer:
      "Если вы не внесли ежемесячный платеж вовремя, то начинает действовать «льготный период». Это значит, что в течение пяти дополнительных дней вы можете успеть погасить просрочку. <br/> <br/>   Если за это время клиент не вносит платеж, то на шестой день ему назначают неустойку. Она равна 20% годовым за один день просрочки. Штраф будут начислять ежемесячно после 15 числа, пока клиент не погасит долг.",
  },
  {
    question: "Какой минимальный и максимальный лимит рассрочки?",
    answer:
      "При получении карты рассрочки вам устанавливают минимальный лимит – 0,1 рубля. Максимальный лимит карты «Халва» составляет 500 000 рублей. Чтобы получить его, достаточно выполнять правила 5х10 и регулярно совершать покупки с помощью карты. Чем активнее вы будете пользоваться Халвой, тем выше вероятность того, что банк увеличит вам лимит.",
  },
  {
    question: "Есть ли платные услуги, которые подключены по умолчанию?",
    answer: "Нет, все услуги мы подключаем с согласия клиента. Они не являются обязательными.",
  },
  {
    question: "Влияет ли наличие карты на кредитную нагрузку?",
    answer:
      "Поскольку с помощью Халвы вы покупаете товары в рассрочку, наличие задолженности по карте влияет на кредитную нагрузку. Банк будет относиться к вам более лояльно, если вы будете активно пользоваться картой и своевременно вносить платежи.  ",
  },
  {
    question: "Сколько времени рассматривают заявку на Халву?",
    answer:
      "Вы получите решение через несколько минут после того, как отправите заявку. В редких случаях на это может потребоваться 1 рабочий день.",
  },
]

export const QUESTIONS_FOR_SHUBA = [
  {
    question: "Как зарегистрироваться в акции?",
    answer:
      "Если у тебя есть карта «Халва», нажми на кнопку регистрации на этом сайте. Ты попадешь на страницу акции, где необходимо активировать участие. Если у тебя нет «Халвы», оформи карту, оставив заявку. После получения не забудь активировать акцию.",
  },
  {
    question: "Какие сроки проведения акции?",
    answer: "22 ноября - 26 декабря 2023 года",
  },
  {
    question: "Когда последний день для совершения покупки, чтобы успеть принять участие в акции?",
    answer:
      "Учитываются покупки, совершенные до 26 декабря включительно. Победителей последней недели мы объявим 29 декабря.",
  },
  {
    question: "Где посмотреть результаты розыгрыша?",
    answer:
      "Мы объявляем победителей каждую пятницу в эфире программы «Шубохранилище» на телеканалах «Пятница!» и «Суббота!», а также списки можно найти <a rel='nofollow noopener' target='_blank' href='https://halvacard.ru/shops/actions/itogi-otkryvaem-shubohranilishe'> на сайте</a>.",
  },
  {
    question: "Сколько шуб будет разыграно?",
    answer: "За весь период акции будет разыграно 64 шубы.",
  },
  {
    question: "Могу ли я выбрать шубу и размер? Как я заберу свой приз?",
    answer: "После победы мы обязательно свяжемся с вами для уточнения всех деталей.",
  },
  {
    question: "У меня нет карты «Халва», могу ли я принять участие?",
    answer:
      "Для участия в розыгрыше необходимо оформить карту «Халва», а после выдачи карты зарегистрироваться в акции.",
  },
  {
    question: "Какие транзакции участвуют в акции?",
    answer:
      "Любые покупки своими деньгами или заемными на сумму от 500 рублей за один чек в любом магазине после активации акции. Оплаты по QR и СБП также участвуют. Исключение - переводы и снятие наличных.",
  },
  {
    question: "Кто не может участвовать в акции?",
    answer: `В акции не участвуют клиенты:  
    <br />
      - с просроченной задолженностью;
      <br />  
      - с заблокированной картой «Халва»;
      <br />
      - с выявленными фактами мошеннических действий;
      <br />
      - держатели карт «Халвенок».
      `,
  },
]

export const QUESTIONS_FOR_SHAPKA = [
  {
    question: "Какой режим работы у зимнего отделения?",
    answer:
      "«Шапка» будет открыта для посетителей с 10 декабря до 8 января с 12:00 до 22:00. <br /> 31 декабря  — с 12:00 до 17:00. А 1-ого января начнем работу с 14:00.",
  },
  {
    question: "Буду ли я получать такой же кэшбэк, как при оплате картой?",
    answer:
      "Да, стикер – это  дополнительная карта к основной «Халве», с ним ты можешь пользоваться преимуществами «Халвы».",
  },
  {
    question: "Обслуживание стикера бесплатно?",
    answer: "Да, при ежемесячных тратах от 10 000 ₽.",
  },
  {
    question: "Стикер PAY — безопасен?",
    answer:
      "Поскольку наклейка PAY — это та же карта, только меньше и удобнее, на ней надежно защищены средства, как на обычной карте. <br /> Личные данные не указываются на стикере, поэтому пользоваться им безопасно. Также можно установить PIN-код для оплаты стикером, задать ограничения на списание средств, заморозить или заблокировать карту в случае ее потери или кражи.",
  },
  {
    question: "У меня уже есть стикер. Могу ли я получить лимитированный?",
    answer:
      "К сожалению, бесплатно заменить ранее выпущенный стикер на лимитированный, не получится. Комиссия за перевыпуск составит 999 ₽.",
  },
]

export const QUESTIONS_FOR_INVITE_FRIEND = [
  {
    question: "Как оформить Халву и сколько это стоит?",
    answer:
      "Оставьте онлайн-заявку на сайте, и в скором времени с вами свяжется сотрудник Совкомбанка, чтобы уточнить детали. Оформление, выпуск и обслуживание карты бесплатные.",
  },
  {
    question: "Как получить карту?",
    answer:
      "Вы можете забрать готовую карту в ближайшем отделении Совкомбанка или заказать доставку курьером в удобное для вас место.",
  },
  {
    question: "Друг поделился ссылкой, чтобы я оформил карту «Халва». Как мне получить бонусы?",
    answer:
      "Необходимо перейти по ссылке, заполнить заявку и в течение 10 дней оформить карту «Халва». Потом еще в течение 10 дней совершить покупки в любых магазинах-партнерах на общую сумму от 1000 рублей. Бонусы поступят на счет после выполнения всех условий.",
  },
  {
    question: "Сколько бонусов я получу, если порекомендую другу оформить карту «Халва»?",
    answer:
      "Сначала 500 или 1000 бонусов поступит на счет друга, потом вы получите вознаграждение в размере 1500 баллов.",
  },
  {
    question: "Что делать с начисленными бонусами?",
    answer: "Конвертировать их в рубли и использовать на своё усмотрение. ",
  },
  {
    question: "Сколько друзей можно привести по ссылке в рамках акции «Приведи друга»?",
    answer:
      "Количество участников не ограничено, но Совкомбанк вправе отказать в выплате бонусов, если будет замечено злоупотребление правом на получение баллов.",
  },
  {
    question: "Что должен сделать друг, чтобы я получил вознаграждение?",
    answer:
      "Оформить карту «Халва» в течение 10 дней после заполнения онлайн-заявки на сайте. Еще в течение 10 дней совершить покупки в любых магазинах-партнерах Халвы на общую сумму от 1000 рублей.",
  },
  {
    question: "Как пригласить друга?",
    answer:
      "Есть несколько способов: <br/>1. Зайдите в  <a rel='nofollow noopener'  target='_blank' href='https://halvacard.ru/lk'>личный кабинет</a> на сайте Халвы. Откройте главную страницу. Пролистайте вниз. В пункте «Предложения» будет кнопка «Пригласите и заработайте».<br/>2. Откройте приложение <a rel='nofollow noopener'  target='_blank' href='https://pwa.sovcombank.ru'>«Халва – Совкомбанк».</a> Выберите карту «Халва» и нажмите на «Пригласите и заработайте».",
  },
  {
    question: "Когда зачислят кэшбэк?",
    answer:
      "В течение 20 дней после выполнения всех условий акции. Предварительная сумма бонусов отобразится в графе «Будет начислено».",
  },
]

export const QUESTIONS_FOR_100_CASHBACK = [
  {
    question: "Халва – кредитная или дебетовая карта?",
    answer:
      "Халва – универсальная карта, которую можно использовать одновременно как дебетовую и карту рассрочки. Сменить режим можно легко в приложении «Халва – Совкомбанк». ",
  },
  {
    question: "Какой у карты срок действия?",
    answer:
      "Халва будет действовать до последнего дня того месяца, который указан на лицевой стороне карты. Договор действителен в течение 10 лет, но сам пластик будет активен только 5 лет. Спустя это время мы бесплатно перевыпустим вам карту. Об истечении срока пластика и дате изготовления банк сообщит заранее. ",
  },
  {
    question: "Что будет, если просрочить ежемесячный платеж?",
    answer:
      "Если вы не внесли ежемесячный платеж вовремя, то начинает действовать «льготный период». Это значит, что в течение пяти дополнительных дней вы можете успеть погасить просрочку. <br/> <br/>   Если за это время клиент не вносит платеж, то на шестой день ему назначают неустойку. Она равна 20% годовым за один день просрочки. Штраф будут начислять ежемесячно после 15 числа, пока клиент не погасит долг.",
  },
  {
    question: "Какой минимальный и максимальный лимит рассрочки?",
    answer:
      "При получении карты рассрочки вам устанавливают минимальный лимит – 0,1 рубля. Максимальный лимит карты «Халва» составляет 500 000 рублей. Чтобы получить его, достаточно выполнять правила 5х10 и регулярно совершать покупки с помощью карты. Чем активнее вы будете пользоваться Халвой, тем выше вероятность того, что банк увеличит вам лимит.",
  },
  {
    question: "Есть ли платные услуги, которые подключены по умолчанию?",
    answer: "Нет, все услуги мы подключаем с согласия клиента. Они не являются обязательными.",
  },
  {
    question: "Влияет ли наличие карты на кредитную нагрузку?",
    answer:
      "Поскольку с помощью Халвы вы покупаете товары в рассрочку, наличие задолженности по карте влияет на кредитную нагрузку. Банк будет относиться к вам более лояльно, если вы будете активно пользоваться картой и своевременно вносить платежи.  ",
  },
  {
    question: "Буду ли я получать уведомления об операциях по карте? Сколько это стоит?",
    answer:
      "По всем операциям на вашей карте будут поступать уведомления. Для этого включите push-уведомления в приложении. <br/> <br/> Помимо этого, вы можете получать СМС-оповещения. Они будут бесплатными, если вы будете соблюдать два простых условия: заходить в приложение банка или личный кабинет на сайте Халвы не менее трех раз в месяц и не отключать push-уведомления в приложении. В ином случае СМС-оповещение станет платным и составит 99 рублей. <br/> <br/> Для клиентов банка старше 50 лет и тех, кто получил, но еще не воспользовался картой, СМС-уведомления бесплатные. ",
  },
  {
    question: "Насколько надежен банк, который выпускает карту? Где о нем можно узнать информацию?",
    answer:
      "За выпуск и обслуживание Халвы ответственен Совкомбанк. На странице банка вы можете найти <a rel='nofollow noopener' target='_blank' href='https://sovcombank.ru/about/info'>всю интересующую информацию</a> – лицензии, реквизиты, рейтинги финансовой организации. Там же вы можете прочитать об истории основания банка. <br/> <br/> Совкомбанк зарекомендовал себя как надежная организация и не раз становился лидером народного рейтинга. В 2018 году Халву признали <a rel='nofollow noopener'  target='_blank' href='https://www.banki.ru/bankofyear/2018/'>лучшей банковской картой года</a> , а в 2021 году банк <a rel='nofollow noopener' target='_blank' href='https://bankofyear.banki.ru/2021/'>возглавил «Народный рейтинг»*</a>. <br/> *по версии портала banki.ru",
  },
  {
    question: "Что такое подписка «Халва.Десятка»?",
    answer:
      "Это платная услуга, с которой вы получаете дополнительные преимущества:<ul><li>увеличенный срок рассрочки у всех партнеров — от 10 месяцев;</li><li>до 17% годовых на остаток своих средств;</li><li>кэшбэк до 10%;</li><li>+1% к ставке вклада при ежемесячном выполнении ПРАВИЛА 5х10;</li><li>услуги личного консультанта по покупкам;</li><li>страхование жизни и здоровья и многое другое;</li><li>+1 месяц рассрочки на снятия, переводы и покупки не у партнёров заемными средствами.</li></ul><br/>Подробнее о Подписке и ее&nbsp;<a rel='nofollow noopener' target='_blank' href='https://halvacard.ru/halvadesyatka/'>преимуществах</a>",
  },
  {
    question: "Сколько времени рассматривают заявку на Халву?",
    answer:
      "Вы получите решение через несколько минут после того, как отправите заявку. В редких случаях на это может потребоваться 1 рабочий день.",
  },
]

export const QUESTIONS_FOR_RASSROCHKA_NA_AUTO = [
  {
    question: "Халва – кредитная или дебетовая карта?",
    answer:
      "Халва – универсальная карта, которую можно использовать одновременно как дебетовую и карту рассрочки. Сменить режим можно легко в приложении «Халва – Совкомбанк». ",
  },
  {
    question: "Какой у карты срок действия?",
    answer:
      "Срок действия карты Рассрочки  Халва  7 лет. В конце срока предусмотрен автоматический перевыпуск.  Достаточно будет прийти за новой картой с паспортом в любое отделение банка.  Перевыпуск и обслуживание карты Совкомбанка полностью бесплатные.",
  },
  {
    question: "Что будет, если просрочить ежемесячный платеж?",
    answer:
      "Если вы не внесли ежемесячный платеж вовремя, то начинает действовать «льготный период». Это значит, что в течение пяти дополнительных дней вы можете успеть погасить просрочку. <br/> <br/>   Если за это время клиент не вносит платеж, то на шестой день ему назначают неустойку. Она равна 20% годовым за один день просрочки. Штраф будут начислять ежемесячно после 15 числа, пока клиент не погасит долг.",
  },
  {
    question: "Какой минимальный и максимальный лимит рассрочки?",
    answer:
      "При получении карты рассрочки вам устанавливают минимальный лимит – 0,1 рубля. Максимальный лимит карты «Халва» составляет 500 000 рублей. Чтобы получить его, достаточно выполнять правила 5х10 и регулярно совершать покупки с помощью карты. Чем активнее вы будете пользоваться Халвой, тем выше вероятность того, что банк увеличит вам лимит.",
  },
  {
    question: "Есть ли платные услуги, которые подключены по умолчанию?",
    answer: "Нет, все услуги мы подключаем с согласия клиента. Они не являются обязательными.",
  },
  {
    question: "Влияет ли наличие карты на кредитную нагрузку?",
    answer:
      "Поскольку с помощью Халвы вы покупаете товары в рассрочку, наличие задолженности по карте влияет на кредитную нагрузку. Банк будет относиться к вам более лояльно, если вы будете активно пользоваться картой и своевременно вносить платежи.  ",
  },
  {
    question: "Буду ли я получать уведомления об операциях по карте? Сколько это стоит?",
    answer:
      "По всем операциям на вашей карте будут поступать уведомления. Для этого включите push-уведомления в приложении. <br/> <br/> Помимо этого, вы можете получать СМС-оповещения. Они будут бесплатными, если вы будете соблюдать два простых условия: заходить в приложение банка или личный кабинет на сайте Халвы не менее трех раз в месяц и не отключать push-уведомления в приложении. В ином случае СМС-оповещение станет платным и составит 99 рублей. <br/> <br/> Для клиентов банка старше 50 лет и тех, кто получил, но еще не воспользовался картой, СМС-уведомления бесплатные. ",
  },
  {
    question: "Насколько надежен банк, который выпускает карту? Где о нем можно узнать информацию?",
    answer:
      "За выпуск и обслуживание Халвы ответственен Совкомбанк. На странице банка вы можете найти <a rel='nofollow noopener' target='_blank' href='https://sovcombank.ru/about/info'>всю интересующую информацию</a> – лицензии, реквизиты, рейтинги финансовой организации. Там же вы можете прочитать об истории основания банка. <br/> <br/> Совкомбанк зарекомендовал себя как надежная организация и не раз становился лидером народного рейтинга. В 2018 году Халву признали <a rel='nofollow noopener'  target='_blank' href='https://www.banki.ru/bankofyear/2018/'>лучшей банковской картой года</a> , а в 2021 году банк <a rel='nofollow noopener' target='_blank' href='https://bankofyear.banki.ru/2021/'>возглавил «Народный рейтинг»*</a>. <br/> *по версии портала banki.ru",
  },
  {
    question: "Что такое подписка «Халва.Десятка»?",
    answer:
      "Это платная услуга, с которой вы получаете дополнительные преимущества:<ul><li>увеличенный срок рассрочки у всех партнеров — от 10 месяцев;</li><li>до 17% годовых на остаток своих средств;</li><li>кэшбэк до 10%;</li><li>+1% к ставке вклада при ежемесячном выполнении ПРАВИЛА 5х10;</li><li>услуги личного консультанта по покупкам;</li><li>страхование жизни и здоровья и многое другое;</li><li>+1 месяц рассрочки на снятия, переводы и покупки не у партнёров заемными средствами.</li></ul><br/>Подробнее о Подписке и ее&nbsp;<a rel='nofollow noopener' target='_blank' href='https://halvacard.ru/halvadesyatka/'>преимуществах</a>",
  },
  {
    question: "Сколько времени рассматривают заявку на Халву?",
    answer:
      "Вы получите решение через несколько минут после того, как отправите заявку. В редких случаях на это может потребоваться 1 рабочий день.",
  },
  {
    question: "Как оформить автомобиль в рассрочку с Халвой?",
    answer:
      "<ol><li>1. Выбрать у дилера автомобиль, который подпадает под условия авторассрочки</li><li>2. Заполнить с дилером заявку на авторассрочку и получить одобрение от банка</li><li>3. Подписать кредитный договор, забрать у дилера карту Халва и Автомобиль</li><li>4. А дальше — получать удовольствие от своего автомобиля и постепенно выплачивать его стоимость банку</li></ol>",
  },
]
