// extracted by mini-css-extract-plugin
export var accordion_root = "questions-module--accordion_root--q3Ubv";
export var accordion_root_expanded = "questions-module--accordion_root_expanded--0ccpk";
export var answers = "questions-module--answers--9pSgP";
export var btn = "questions-module--btn--CWbz1";
export var details_root = "questions-module--details_root--oz9bZ";
export var expand_icon = "questions-module--expand_icon--eCu+2";
export var link = "questions-module--link--BaJat";
export var section = "questions-module--section--L+2Wt";
export var summary_content = "questions-module--summary_content--1LGrJ";
export var summary_root = "questions-module--summary_root--VwHtQ";
export var title = "questions-module--title--Gz3Pf";
export var withButtons = "questions-module--withButtons--PreU3";